import React from 'react'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { ImageSourcePropType } from 'react-native'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { PaddedContentAreaConditional } from '../../../components/layout/content-area-padded-conditional'
import { BodyText } from '../../../components/common/copy/text-body'
import { HeadingMain } from '../../../components/common/copy/heading-main'
import { Center } from '../../../components/common/center/center'
import { getScriptTypeForLanguage } from '../../../modules/language-helpers/language-helpers'
import { InfoText } from '../../common/copy/text-info'
import { Row } from '../../common/row/row'
import { TagIcon } from '../tags/tag-icon'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { Link } from '../../common/link/link'
import { useColorModeValue } from 'native-base'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../../constants/ui-constants'

type ProfileProps = {
  img: ImageSourcePropType
  title: string
  subTitle?: string
  location?: string
  language?: SupportedLanguage
  description?: string
  linkProps?: any
  website?: string
  email?: string
}

export const Profile = ({
  img,
  title,
  subTitle,
  language,
  location,
  email,
  website,
  description,
}: ProfileProps) => {
  // I18N
  const lang = language || 'en'
  const scriptType = getScriptTypeForLanguage(lang)

  //  VARS
  const bodyTextAlign = (description || '').length > 500 ? 'left' : 'center'
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  return (
    <PaddedContentAreaConditional pointerEvents={'auto'} webPadding={0} pb={16}>
      <Center mt={4}>
        <AvatarCircle
          source={img}
          mb={2}
          size={'xl'}
          name={title}
          disablePress={true}
        />
      </Center>
      <Center>
        <HeadingMain size={'2xl'} mb={2} mt={8} scriptType={scriptType}>
          {title}
        </HeadingMain>
        {subTitle && (
          <InfoText mt={4} textAlign={'center'}>
            {subTitle}
          </InfoText>
        )}
        {location && (
          <Row alignItems={'center'} mt={2} ml={-1}>
            <TagIcon
              borderRadius={'full'}
              iconKey={'SETTING'}
              color={mutedColor}
            />
            <InfoText>{location}</InfoText>
          </Row>
        )}
        {email && (
          <Row alignItems={'center'} mt={2} ml={-1}>
            <ElsewhereIcon iconKey="letter" color={mutedColor} size={'xs'} />
            <Link href={`mailto:${email}`}>
              <InfoText
                mt={2}
                marginX={2}
                textAlign={'center'}
                style={{ textDecorationLine: 'underline' }}
              >
                {email}
              </InfoText>
            </Link>
          </Row>
        )}
        {website && (
          <Row alignItems={'center'} mt={2} ml={-1}>
            <ElsewhereIcon iconKey="link" color={mutedColor} size={'xs'} />
            <Link href={website}>
              <InfoText
                mt={2}
                marginX={2}
                textAlign={'center'}
                style={{ textDecorationLine: 'underline' }}
              >
                {website}
              </InfoText>
            </Link>
          </Row>
        )}
      </Center>
      {description && (
        <BodyText textAlign={bodyTextAlign} mt={8}>
          {description}
        </BodyText>
      )}
    </PaddedContentAreaConditional>
  )
}
