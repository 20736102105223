import { AboutSection } from '../../../api/frontend-types'
import { LINKS } from '../constants/constants'
import appInfo from '../../../mobile/package.json'

export const aboutTeam: AboutSection = {
  headingKey: 'common.team',
  members: [
    {
      headingKey: 'about.developers',
      members: [
        {
          name: 'Gez Quinn',
          scriptType: 'latin',
          link: 'https://gezquinn.design/',
        },
        {
          name: 'Sheldon Juncker',
          scriptType: 'latin',
          link: 'https://www.linkedin.com/in/jundar/',
        },
        {
          name: 'Dan Kennedy',
          scriptType: 'latin',
          link: 'https://dannykennedy.co/',
        },
      ],
    },
    {
      headingKey: 'about.design',
      members: [
        {
          name: 'Lili Köves',
          scriptType: 'latin',
          link: 'https://www.behance.net/lili_koves',
        },
      ],
    },
    {
      headingKey: 'about.illustration',
      members: [
        {
          nameKey: 'names.yohYasuda',
          link: 'https://yo-yo-yasuda.tumblr.com/',
        },
      ],
    },
    {
      headingKey: 'about.researchCoordinator',
      members: [
        {
          name: 'Victoria Philibert',
          scriptType: 'latin',
        },
      ],
    },
    {
      headingKey: 'about.advisor',
      members: [
        {
          name: 'Kelly Bulkeley',
          scriptType: 'latin',
          link: 'https://sleepanddreamdatabase.org/',
        },
      ],
    },
  ],
}

export const aboutTranslators: AboutSection = {
  headingKey: 'about.translation',
  members: [
    {
      headingKey: 'about.translators',
      members: [
        {
          name: 'Sophie Boudrias',
          scriptType: 'latin',
          link: 'https://www.linkedin.com/in/sophie-boudrias-631339136/?originalSubdomain=ca',
        },
        {
          name: 'Deidra Mesayu',
          scriptType: 'latin',
          link: 'https://www.instagram.com/ramesayur/',
        },
        {
          nameKey: 'names.yohYasuda',
          link: 'https://yo-yo-yasuda.tumblr.com/',
        },
        {
          name: 'Dan Kennedy',
          scriptType: 'latin',
          link: 'https://dannykennedy.co/',
        },
        {
          name: 'Yuriy Trakalo',
          scriptType: 'latin',
          link: 'https://www.instagram.com/cinycus/',
        },
        {
          nameKey: 'names.darynaTatarenko',
        },
        {
          name: 'Mathias Tolstrup Engstrøm',
          scriptType: 'latin',
          link: 'https://www.instagram.com/dub_across_borders/',
        },
      ],
    },
  ],
}

export const aboutContactDetails: AboutSection = {
  headingKey: 'common.contact',
  members: [
    {
      headingKey: 'common.email',
      members: [
        {
          name: 'team@elsewhere.to',
          scriptType: 'latin',
          link: 'mailto:team@elsewhere.to',
        },
      ],
    },
    {
      headingKey: 'common.discord',
      members: [
        {
          nameKey: 'communitySocialPage.joinTheCommunity',
          link: LINKS.discord,
        },
      ],
    },
  ],
}

export const aboutVersion: AboutSection = {
  headingKey: 'common.versionInfo',
  members: [
    {
      headingKey: 'about.version',
      members: [
        {
          name: appInfo.version,
          scriptType: 'latin',
        },
      ],
    },
  ],
}

export const aboutLegal: AboutSection = {
  headingKey: 'common.legal',
  members: [
    {
      headingKey: 'common.privacyPolicy',
      members: [
        {
          name: 'elsewhere.to/privacy',
          scriptType: 'latin',
          link: 'https://elsewhere.to/privacy/',
        },
      ],
    },
    {
      headingKey: 'common.termsOfService',
      members: [
        {
          name: 'elsewhere.to/terms',
          scriptType: 'latin',
          link: 'https://elsewhere.to/terms/',
        },
      ],
    },
  ],
}

export const aboutCommunityAndSocials: AboutSection = {
  headingKey: 'common.communityAndSocials',
  members: [
    {
      headingKey: 'common.discord',
      members: [
        {
          nameKey: 'communitySocialPage.joinTheCommunity',
          link: LINKS.discord,
        },
      ],
    },
    {
      heading: 'Instagram',
      scriptType: 'latin',
      members: [
        {
          name: '@elsewheredreams',
          scriptType: 'latin',
          link: LINKS.instagram,
        },
      ],
    },
    {
      heading: 'X',
      scriptType: 'latin',
      members: [
        {
          name: '@elsewheredreams',
          scriptType: 'latin',
          link: LINKS.x,
        },
      ],
    },
    {
      heading: 'Facebook',
      scriptType: 'latin',
      members: [
        {
          name: '/elsewheredreams',
          scriptType: 'latin',
          link: LINKS.facebook,
        },
      ],
    },
  ],
}

export const aboutDirectContact: AboutSection = {
  headingKey: 'common.contact',
  members: [
    {
      headingKey: 'common.email',
      members: [
        {
          name: 'team@elsewhere.to',
          scriptType: 'latin',
          link: 'mailto:team@elsewhere.to',
        },
      ],
    },
    {
      headingKey: 'common.web',
      members: [
        {
          name: 'elsewhere.to',
          scriptType: 'latin',
          link: 'https://elsewhere.to',
        },
      ],
    },
  ],
}
