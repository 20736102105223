import React from 'react'
import { ImageURISource, Platform } from 'react-native'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import { Row } from '../../common/row/row'
import { Column } from '../../common/column/column'
import { InfoText } from '../../common/copy/text-info'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { TagIcon } from '../tags/tag-icon'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { RootStackParamList } from '../../../navigation/types'
import { useColorModeValue } from 'native-base'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../../constants/ui-constants'

type FeedEntryCondensedProps = {
  maxChars?: number
  imageSource?: ImageURISource
  title: string
  subTitle?: string
  linkProps: {
    screen: keyof RootStackParamList
    params?: any
  }
  location?: string
}

const FeedEntryDreamService = ({
  imageSource,
  title,
  subTitle,
  linkProps,
  location,
}: FeedEntryCondensedProps) => {
  const isWeb = Platform.OS === 'web'
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  return (
    <Row
      alignItems={'center'}
      justifyContent={'space-between'}
      minHeight={75}
      paddingLeft={3}
      paddingRight={3}
      paddingY={4}
    >
      <Row>
        <Row>
          <AvatarCircle size={'md'} source={imageSource} name={title} />
          <Column marginX={3} paddingTop={1}>
            <ConditionalLink condition={isWeb} to={linkProps}>
              <SansText>{title}</SansText>
            </ConditionalLink>
            <InfoText>{subTitle}</InfoText>
            <Row alignItems={'center'} mt={2} ml={-1}>
              <TagIcon
                borderRadius={'full'}
                iconKey={'SETTING'}
                color={mutedColor}
              />
              <InfoText>{location}</InfoText>
            </Row>
          </Column>
        </Row>
      </Row>
      <ElsewhereIcon iconKey="caret-i18n" />
    </Row>
  )
}

export default FeedEntryDreamService
