import React from 'react'
import { SupportedLanguage } from '../../../../api/frontend-types'
import { InfoText } from '../../components/common/copy/text-info'
import Autolink from 'react-native-autolink'
import { ElsewhereIcon } from '../../components/common/elsewhere-icon/elsewhere-icon'
import { LIGHT_MID_GRAY } from '../../constants/ui-constants'
import { Column } from '../../components/common/column/column'
import { Link } from '../../components/common/link/link'
import { useTranslation } from 'react-i18next'
import { useLinkProps } from '../../utilities/useLinkPropsFixed'

type DreamWorkerFooterProps = {
  language: SupportedLanguage
}

// interpretationPage.goDeeperLink
export const DreamWorkerFooter = ({ language }: DreamWorkerFooterProps) => {
  const { t } = useTranslation()

  const { onPress: dreamworkersOnPress, ...restCredits } = useLinkProps({
    to: {
      screen: 'PrivateDreamServicesView',
      params: { languageSpoken: language },
    },
  })

  return (
    <Column mt={8} alignItems={'center'}>
      <ElsewhereIcon size={'xs'} iconKey="search" color={LIGHT_MID_GRAY} />
      <Link></Link>
      <InfoText mt={3} maxW={300} textAlign={'center'}>
        <Autolink
          text={t('interpretationPage.goDeeperLink')}
          matchers={[
            {
              pattern: /<linkTag>(.*)<\/linkTag>/g,
              getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
              onPress: () => dreamworkersOnPress(),
            },
          ]}
          linkProps={{
            style: {
              textDecorationLine: 'underline',
            },
            onPress: dreamworkersOnPress,
            ...restCredits,
          }}
        />
      </InfoText>
    </Column>
  )
}
