import React, { memo } from 'react'
import { PADDING_TO_TABS } from '../../../../constants/ui-constants'
import { FeedItemDreamInterpretation } from '../../feed/feed-item-dream-interpretation'
import { FEED_ITEM_MAX_CHARS } from '../../../../constants/constants'
import { RootStackParamList } from '../../../../navigation/types'
import { Column } from '../../../common/column/column'
import {
  InterpretationBasic,
  SupportedLanguage,
} from '../../../../../../api/frontend-types'
import i18n from '../../../../i18n/i18nnext'
import { DreamWorkerFooter } from '../../../../screens/InterpretationView/dreamworker-footer'
import { dreamservicesByLanguage } from '../../../../i18n/dream-services'

type InterpretationTabContentsProps = {
  interpretations: InterpretationBasic[]
  onDeleteInterpretation: (id: string) => void
  language: SupportedLanguage
}

export const InterpretationTabContents = memo(
  ({
    interpretations,
    onDeleteInterpretation,
    language,
  }: InterpretationTabContentsProps) => {
    const hasInterpretations = interpretations.length >= 1
    const oneInterpretation = interpretations.length === 1

    // Add dreamworker link if one is available
    const userLanguage = (i18n.resolvedLanguage || 'en') as SupportedLanguage
    const dreamWorkersAvailable = Boolean(dreamservicesByLanguage[userLanguage])

    const ExtraFooter: JSX.Element | undefined = dreamWorkersAvailable ? (
      <DreamWorkerFooter language={userLanguage} />
    ) : undefined

    return (
      <Column w={'100%'} py={PADDING_TO_TABS}>
        {/* INTERPRETATIONS */}
        {hasInterpretations &&
          interpretations.map((interp: InterpretationBasic, i) => {
            const linkObject = {
              screen: 'PrivateInterpretationView' as keyof RootStackParamList,
              params: { id: interp.id, language },
            }
            return (
              <FeedItemDreamInterpretation
                key={interp.id + i}
                interpretation={interp}
                truncationLength={
                  // Don't truncate if there's only one interpretation
                  oneInterpretation ? undefined : FEED_ITEM_MAX_CHARS
                }
                linkObject={linkObject}
                hasTopDivider={i !== 0}
                onDeleteInterpretation={onDeleteInterpretation}
                language={language}
                extraFooter={ExtraFooter}
              />
            )
          })}
      </Column>
    )
  },
  shouldInterpListUpdate,
)

function shouldInterpListUpdate(
  prevProps: InterpretationTabContentsProps,
  nextProps: InterpretationTabContentsProps,
) {
  const prevInterps = prevProps.interpretations.map((x) => x.id).join('')
  const nextInterps = nextProps.interpretations.map((x) => x.id).join('')
  return prevInterps === nextInterps
}
