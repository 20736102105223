import React from 'react'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { useTranslation } from 'react-i18next'
import { Column } from '../../components/common/column/column'
import { ListRenderItem, Platform } from 'react-native'
import { TabView } from '../../components/layout/tab-view'
import { DreamworkService } from '../../../../api/frontend-types'
import { dreamServices } from '../../i18n/dream-services'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
  RootStackParamList,
  // RootStackNavigationProp,
  // RootStackScreenProps,
} from '../../navigation/types'
import { InfoText } from '../../components/common/copy/text-info'
import FeedEntryDreamService from '../../components/composite/feed/feed-entry-dreamservice'
import { SansText } from '../../components/common/copy/text-sans'
import { Profile } from '../../components/composite/profile/profile-basic'

export type PrivateDreamServiceViewNavigationProp =
  MainStackNavigationProp<'PrivateDreamServiceView'>

// PrivateDreamServicesView
export default function DreamServiceViewComponent({
  route,
}: MainStackScreenProps<'PrivateDreamServiceView'>) {
  // HOOKS
  const { t } = useTranslation()

  // I18N
  const { serviceId } = route.params

  const service = dreamServices.find(
    (service) => service.serviceId === serviceId,
  )

  if (!service) {
    return null
  } else {
    return (
      <Column>
        <Profile
          title={service.name}
          location={service.location}
          img={{ uri: service.imageUrl }}
          description={service.description}
          email={service.contactEmail}
          website={service.infoUrl}
        />
      </Column>
    )
  }
}
