import React, { FC, ReactNode } from 'react'
import i18n from '../../../i18n/i18nnext'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { TextProps, Text } from './text'
import {
  getBodyFont,
  getFontSuffix,
  isRtlLanguage,
} from '../../../modules/language-helpers/language-helpers'
import {
  BODY_FONT_WEIGHT_STR,
  LARGE_FONT_SIZE,
  LARGE_LINE_HEIGHT,
  SELECTION_COLOR,
} from '../../../constants/constants'
import {
  MUTED_TEXT_COLOR_DARK,
  MUTED_TEXT_COLOR_LIGHT,
} from '../../../constants/ui-constants'
import { useColorModeValue } from 'native-base'

type TextContentProps = TextProps & {
  children?: ReactNode
  type?: 'primary' | 'gray'
  language?: SupportedLanguage
}

export const BodyText: FC<TextContentProps> = ({
  children,
  fontSize = LARGE_FONT_SIZE,
  fontWeight = BODY_FONT_WEIGHT_STR,
  fontStyle = 'normal',
  type = 'primary',
  language,
  ...rest
}: TextContentProps) => {
  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const fontFamily = getBodyFont(lang)
  const isRtl = isRtlLanguage(lang)
  const textAlign = isRtl ? 'right' : 'left'

  // No italics used in RTL languages (at least Arabic/Hebrew)
  // TODO: perhaps render as bold instead?
  const fStyle = isRtl ? 'normal' : fontStyle

  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  return (
    <Text
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      selectionColor={SELECTION_COLOR}
      selectable={true}
      lineHeight={LARGE_LINE_HEIGHT}
      color={type === 'gray' ? mutedColor : undefined}
      textAlign={textAlign}
      fontStyle={fStyle}
      {...rest}
    >
      {children}
    </Text>
  )
}
