import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FeedEntryGeneric } from './feed-entry-generic'
import { useSelector } from '../../../ducks/root-reducer'
import { selectInterpStyleById } from '../../../ducks/ui/ui'
import { interpreters } from '../../../constants/ui-constants'
import {
  InterpretationBasic,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import { InterpretationStyleKey } from '../../../../../api/frontend-types'
import {
  MainStackParamList,
  RootStackParamList,
} from '../../../navigation/types'
import { ActionSheetMenuItem } from '../../common/action-sheet/action-sheet-menu'
import { copyToClipboard } from '../../../modules/ui-helpers/copy-to-clipboard'
import { markInsightAsDeleted } from '../../../ducks/dream-tag/functions/insight-functions'
import { ContentDivider } from '../../layout/content-divider'
import { HeadingSmall } from '../../common/copy/heading-small'
import { BodyTextMarkdown } from '../../common/copy/text-body-markdown'
import i18n from '../../../i18n/i18nnext'

type FeedItemInterpretationProps = {
  interpretation: InterpretationBasic
  language: SupportedLanguage
  truncationLength?: number
  hasTopDivider?: boolean
  linkObject?: {
    screen: keyof RootStackParamList | keyof MainStackParamList
    params: {
      id: string
    }
  }
  extraFooter?: JSX.Element
  onDeleteInterpretation: (id: string) => void
}
export const FeedItemDreamInterpretation = memo(
  ({
    interpretation,
    language,
    truncationLength,
    linkObject,
    hasTopDivider,
    extraFooter,
    onDeleteInterpretation,
  }: FeedItemInterpretationProps) => {
    // HOOKS
    const { t } = useTranslation()

    // SELECTORS
    const interpStyle = useSelector(
      selectInterpStyleById(interpretation.styleId),
    )

    // VARS
    // Get corresponding key for interpretation id (e.g. 'freudian')
    const interpKey: InterpretationStyleKey | undefined =
      interpStyle?.key as InterpretationStyleKey
    const key: InterpretationStyleKey = interpKey || 'standard'

    const interpreter = interpreters[key]
    const { img, i18nKey } = interpreter
    const styleLabel = t(i18nKey)
    // User language (may be different from interpretation language)
    const userLanguage = (i18n.resolvedLanguage as SupportedLanguage) || 'en'

    const userInfoLinkObject = {
      screen: 'InterpretationStyle' as keyof RootStackParamList,
      params: { style: interpKey },
    }

    const actionSheetMenuItems: ActionSheetMenuItem[] = [
      {
        label: t('common.copyToClipboard'),
        closesMenu: true,
        onPress: () => {
          copyToClipboard(interpretation.text, t)
        },
      },
      {
        label: t('common.delete'),
        closesMenu: true,
        onPress: () => {
          if (interpretation) {
            markInsightAsDeleted(interpretation.id)
              .then((result) => {
                if (result) {
                  onDeleteInterpretation(interpretation.id)
                }
              })
              .catch((e) => {
                console.log('Error deleting insight')
                console.error(e)
              })
          }
        },
      },
      {
        label: t('common.cancel'),
        closesMenu: true,
        onPress: () => {},
      },
    ]
    const diaryInterpretation = interpretation.diaryInterpretation
      ? '\n\n' + interpretation.diaryInterpretation
      : ''

    const DescriptionComponent = () => {
      return (
        <>
          <BodyTextMarkdown>{interpretation.text}</BodyTextMarkdown>
          <ContentDivider />
          <HeadingSmall mt={0}>Waking Life Associations</HeadingSmall>
          <BodyTextMarkdown>{diaryInterpretation}</BodyTextMarkdown>
        </>
      )
    }

    return (
      <>
        <FeedEntryGeneric
          inGroup={false}
          description={interpretation.text + diaryInterpretation}
          descriptionComponent={
            interpretation.diaryInterpretation ? (
              <DescriptionComponent />
            ) : undefined
          }
          avatarLabel={styleLabel}
          avatarLabelLanguage={userLanguage}
          avatarCircleSrc={img}
          truncationLength={truncationLength}
          linkObject={linkObject}
          hasTopDivider={hasTopDivider}
          disableUserInfoRowPress={true}
          userInfoLinkObject={userInfoLinkObject}
          actionSheetCanOpen={true}
          actionSheetMenuItems={actionSheetMenuItems}
          language={language}
          extraFooter={extraFooter}
        />
      </>
    )
  },
  shouldRefresh,
)

function shouldRefresh(
  prevProps: FeedItemInterpretationProps,
  nextProps: FeedItemInterpretationProps,
) {
  return prevProps.interpretation.id === nextProps.interpretation.id
}
